import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import Main from "./components/layout/Main.jsx";
import Earn from "./pages/Earn.jsx";
import Friends from "./pages/Friends.jsx";
import Home from "./pages/Home.jsx";
import Notifications from "./pages/Notifications.jsx";
import { useEffect } from "react";
import {
  useDispatch
} from "react-redux";
import { addUserClicks } from "./redux/userClick.js";
import TON from "./pages/TON/index.jsx";

export default function NavigationWrapper() {
  const dispatch = useDispatch();
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Main>
          <Home />
        </Main>
      ),
    },
    {
      path: "/friends",
      element: (
        <Main showStats={false}>
          <Friends />
        </Main>
      ),
    },
    {
      path: "/earn",
      element: (
        <Main showStats={false}>
          <Earn />
        </Main>
      ),
    },
    {
      path: "/ton",
      element: (
        <Main
          showStats={false}
        >
          <TON />
        </Main>
      )
    },
    {
      path: "/notifications",
      element: (
        <Main
          showStats={false}
        >
          <Notifications />
        </Main>
      )
    }
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(addUserClicks(1))
    }, 500);
    return () => clearInterval(interval);
  }, []);

  return <RouterProvider router={router} />;
}
