export function formatNumber(num) {
    // Check if the number is greater than or equal to 1 billion
    if (Math.abs(num) >= 1e9) {
        return (num / 1e9).toFixed(1).replace(/\.0$/, '') + 'B';
    }
    // Check if the number is greater than or equal to 1 million
    else if (Math.abs(num) >= 1e6) {
        return (num / 1e6).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    // Check if the number is greater than or equal to 1 thousand
    else if (Math.abs(num) >= 1e3) {
        return (num / 1e3).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    // Return the original number if it is less than 1 thousand
    else {
        return num.toString();
    }
}
