import { Spin, Typography } from "antd";

export default function LoadingScreen(){
    return (
        <div
            className="h-screen w-screen flex flex-col gap-y-2 items-center justify-center"
        >
            <Spin
                size="large"
            />
        </div>
    )
}