import WebApp from "@twa-dev/sdk";
import ThemeWrapper from "./ThemeWrapper";
import { useEffect } from "react";
import InvalidPlatform from "./pages/InvalidPlatform";
import { logFirebaseEvent } from "./services/firebase";

export default function DeviceWrapper(){
    const isInvalidPlatform = WebApp.platform !== "android" && WebApp.platform !== "android_x" && WebApp.platform !== "ios";

    useEffect(() => {
        WebApp.expand();
        logFirebaseEvent("app_open", {
            platform: WebApp?.platform || "unknown"
        });
    }, []);

    if (isInvalidPlatform){
        return (
            <InvalidPlatform />
        );
    }

    return (
        <ThemeWrapper />
    );
}