import WebApp from "@twa-dev/sdk";
import { PiShovelDuotone, PiUsersThreeDuotone, PiCoinsDuotone, PiCurrencyCircleDollarFill  } from "react-icons/pi";
import { Link } from "react-router-dom";
import DollarImage from "../../assets/icons/dollar.png";

export default function NavBar(){
    const navigationItems = [
        {
            title: "Mine",
            type: "icon",
            asset: PiShovelDuotone,
            href: "/",
            selected: window.location.pathname === "/"
        },
        {
            title: "Friends",
            type: "icon",
            asset: PiUsersThreeDuotone,
            href: "/friends",
            selected: window.location.pathname === "/friends"
        },
        {
            title: "Earn",
            type: "icon",
            asset: PiCoinsDuotone,
            href: "/earn",
            selected: window.location.pathname === "/earn"
        },
        {
            title: "Airdrop",
            type: "image",
            asset: DollarImage,
            href: "/ton",
            selected: window.location.pathname === "/ton"
        }
    ];
    return (
        <nav
            className="bg-[var(--tg-theme-accent-text-color)] p-1.5 rounded-xl flex gap-x-1.5 justify-evenly items-center mx-auto w-[90vw]"
        >
            {
                navigationItems.map((item, index) => (
                    <Link
                        key={index}
                        to={item.href}
                        onClick={() => {
                            WebApp.HapticFeedback.impactOccurred("medium");
                        }}
                        className={`no-underline flex flex-1 flex-col items-center gap-y-1 p-0.5 rounded-lg ${item.selected ? "bg-[var(--tg-theme-bg-color)]" : ""} `}
                    >
                        {
                            item.type === "icon" && (
                                <item.asset
                                    size={24}
                                    color={item.selected ? "var(--tg-theme-button-color)" : "var(--tg-theme-button-text-color)"}
                                />
                            )
                        }
                        {
                            item.type === "image" && (
                                <img
                                    src={item.asset}
                                    alt={item.title}
                                    className="w-6 h-6"
                                />
                            )
                        }
                        <span
                            className={`text-xs no-underline ${item.selected ? "text-[var(--tg-theme-button-color)]" : "text-[var(--tg-theme-button-text-color)]"}`}
                        >
                            {item.title}
                        </span>
                    </Link>
                ))
            }
        </nav>
    );
}