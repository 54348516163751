const BottomSheet = ({ isOpen, onClose, children }) => {

  return (
    <>
      {isOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-50"
          onClick={onClose}
        ></div>
      )}

      <div
        className={`fixed inset-x-0 bottom-0 max-h-screen overflow-hidden transition-transform duration-300 ${
          isOpen ? "translate-y-0 z-[51]" : "translate-y-full"
        }`}
      >
        <div className="bg-[var(--tg-theme-bg-color)] p-3 shadow-lg rounded-t-xl">
            {children}
        </div>
      </div>
    </>
  );
};

export default BottomSheet;
