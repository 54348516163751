import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { PiMoneyWavyBold, PiCheckCircleDuotone } from "react-icons/pi";
import WebApp from '@twa-dev/sdk'
import { Spin, Typography, message } from "antd";
import calendarImage from "../assets/icons/dollar-calendar.png";
import telegramImage from "../assets/icons/telegram.png";
import xImage from "../assets/icons/x.png";
import chatIcon from "../assets/icons/chat.png";
import GRTShadowPng from "../assets/logo/grt-shadow.png";
import { useTasks } from "../hooks/tasks";
import useFetch from "../hooks/fetch";
import { useDispatch } from "react-redux";
import { update } from "../redux/userSlice";
import { updateTask } from "../redux/tasksSlice";
import CoinsCreditedBottomSheet from "../components/CoinsCreditedBottomSheet";
import playCoinCreditAudio from "../services/playCoinCreditAudio";
import { logFirebaseEvent } from "../services/firebase";
import BottomSheet from "../components/BottomSheet";
import DailyTask from "../components/DailyTask";
import { updateLoginStreak } from "../redux/loginStreakSlice";

function NewTag(){
    return (
        <div
            className="bg-white rounded-full px-2"
        >
            <span
                className="text-xs text-black font-semibold leading-none"   
            >
                New
            </span>
        </div>
    )
}

export default function Earn(){
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const tasks = useTasks();
    const _fetch = useFetch();

    const defaultTasks = [
        {
            _id: "default-task-1",
            title: "Invite Friends",
            key: "invite",
            completed: false,
            image: getDBTaskImage("invite"),
            description: "Upto +25000 GRTs on completion"
        }
    ];

    const [dailyTaskOpen, setDailyTaskOpen] = useState(false);
    const [loading, setLoading] = useState(null);
    const [dailyStreakLoading, setDailyStreakLoading] = useState(false);
    const [coinsCredited, setCoinsCredited] = useState(0);

    function getDBTaskImage(key){
        if(key === "invite") return chatIcon;
        else if (key === "telegram") return telegramImage;
        else if (key === "twitter") return xImage;
        else if (key === "daily") return calendarImage;
        else return null;
    }

    let dbTasks = [];

    if (tasks.length > 0) {
        dbTasks = tasks.map((task) => {
            return {
                ...task,
                image: getDBTaskImage(task.key),
                description: `+${task.coins} GRTs on completion`
            }
        })
    }

    dbTasks = [...dbTasks, ...defaultTasks];

    const earnSections = [
        {
            title: "Daily Tasks",
            isNew: true,
            items: [
                {
                    _id: "default-daily-task-1",
                    key: "daily",
                    title: "Daily Rewards",
                    description: "Claim your daily rewards",
                    image: getDBTaskImage("daily"),
                    completed: false,
                }
            ],
        },
        {
            title: "Tasks List",
            isNew: false,
            items: dbTasks
        }
    ]

    async function redeemTask(taskId){
        try {
            setLoading(taskId);
            const res = await _fetch("task/complete", {
                method: "POST",
                body: {
                    "task": taskId
                }
            });

            if (res.success){
                playCoinCreditAudio();
                message.success("Task completed successfully");
                const {
                    task,
                    user
                } = res.response;
                dispatch(update(user));
                dispatch(updateTask(task));
                setCoinsCredited(task.coins);
                logFirebaseEvent("task_completed", {
                    task: task.key,
                    coins: task.coins
                });
            } else {
                message.error("Failed to complete task");    
            }
        } catch (err){
            console.error(err);
        } finally {
            setLoading(null);
        }
    }

    function handleDailyTaskClick(){
        setDailyTaskOpen(true);
    }

    function hideDailyTask(){
        setDailyTaskOpen(false);
    }

    function onTaskClick(task){
        if (!task.completed && !loading){
            if (task.key === "invite") {
                navigate("/friends");
            } else if (task.key === "daily"){
                handleDailyTaskClick();
            } else {
                const taskUrl = task.url;
                if (taskUrl) {
                    WebApp.openLink(taskUrl);
                    redeemTask(task?._id);
                } else {
                    message.error("Task not available yet.");
                    logFirebaseEvent("task_not_available", {
                        task: task.key
                    });
                }
            }
        }
    }

    async function handleDailyStreakCompletion(){
        try {
            setDailyStreakLoading(true);
            const res = await _fetch("user/login-streak", {
                method: "POST"
            });
            if (res.success){
                const {
                    streak,
                    rewardCoins,
                    updatedUser
                } = res.response;
                if (updatedUser){
                    playCoinCreditAudio();
                    hideDailyTask();
                    setCoinsCredited(rewardCoins);
                    dispatch(update(updatedUser));
                    dispatch(updateLoginStreak(streak));

                    logFirebaseEvent("daily_streak_completed", {
                        streak,
                        rewardCoins
                    });
                } else {
                    message.error("Wait for tomorrow to claim your reward");
                }
            } else {
                message.error("Failed to complete daily streak");
            }
        } catch (err) {
            console.error(err);
            message.error("Failed to complete daily streak");
        } finally {
            setDailyStreakLoading(false);
        }
    }

    useEffect(() => {
        function setBackAction(){
            WebApp.BackButton.show();
            WebApp.BackButton.onClick(() => {
                navigate("/");
            });
        }
        setBackAction();
    }, []);

    return (
        <div>
            <div
                className="flex justify-center items-center"
            >
                <img
                    src={GRTShadowPng}
                    alt="GRT"
                    className="h-72 object-contain"
                />
            </div>
            <Typography.Title
                level={2}
                className="text-center"
            >
                Earn more coins
            </Typography.Title>
            <div
                className="flex flex-col gap-y-4"
            >
                {
                    earnSections.map((section, index) => (
                        <div
                            key={`earn-section-${index}`}
                        >
                            <Typography.Text
                                className="block text-left font-medium"
                            >
                                {section.title}
                            </Typography.Text>
                            <div
                                className="flex flex-col gap-y-2 mt-2"
                            >
                                {
                                    section.items.map((item) => (
                                        <div
                                            key={item._id}
                                            className="flex flex-row gap-x-2 items-center p-2 bg-[var(--tg-theme-accent-text-color)] rounded-2xl cursor-pointer"
                                            onClick={() => onTaskClick(item)}
                                        >
                                            {
                                                item.image ? (
                                                    <img
                                                        src={item.image}
                                                        alt={item.title}
                                                        className="w-12 object-contain"
                                                    />
                                                ) : (
                                                    <div
                                                        className="w-12 h-full flex items-center justify-center"
                                                    >
                                                        <PiMoneyWavyBold
                                                            size={32}
                                                            color="var(--tg-theme-button-text-color)"
                                                        />
                                                    </div>
                                                )
                                            }
                                            <div
                                                className="flex flex-col"
                                            >
                                                <div
                                                    className="flex gap-x-2"
                                                >
                                                    <Typography.Text
                                                        className="block text-left text-[var(--tg-theme-button-text-color)] font-semibold"
                                                    >
                                                        {item.title}
                                                    </Typography.Text>
                                                    {
                                                        section?.isNew && (
                                                            <NewTag />
                                                        )
                                                    }
                                                </div>
                                                <Typography.Text
                                                    className="block text-left text-sm text-[var(--tg-theme-button-text-color)]"
                                                >
                                                    {item.description}
                                                </Typography.Text>
                                            </div>
                                            <div
                                                className="flex flex-1 justify-end"
                                            >
                                                {
                                                    item.completed && (
                                                        <PiCheckCircleDuotone
                                                            size={28}
                                                            color="var(--tg-theme-button-text-color)"
                                                        />
                                                    )
                                                }
                                                {
                                                    !item.completed && loading === item?._id && (
                                                        <Spin
                                                            size="small"
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
            <CoinsCreditedBottomSheet
                coins={coinsCredited}
                isOpen={coinsCredited > 0}
                onClose={() => setCoinsCredited(0)}
            />
            <BottomSheet
                isOpen={dailyTaskOpen}
                onClose={hideDailyTask}
            >
                <DailyTask
                    onTaskComplete={handleDailyStreakCompletion}
                />
            </BottomSheet>
            
        </div>
    );
}