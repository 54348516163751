import { createSlice } from "@reduxjs/toolkit";

const initialConstState = {
    streakRewards: null,
    levelNameMapping: null,
    levelThresholds: null,
    inviteRewards: null,
};

const constSlice = createSlice({
    name: "consts",
    initialState: initialConstState,
    reducers: {
        setConsts: (_, action) => {
            return action.payload;
        },
        updateConsts: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        }
    }
});

export const {
    setConsts,
    updateConsts,
} = constSlice.actions;

export default constSlice.reducer;