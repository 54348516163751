import { createSlice } from "@reduxjs/toolkit";

const initialStreakState = {

};

const loginStreakSlice = createSlice({
    name: "loginStreak",
    initialState: initialStreakState,
    reducers: {
        setLoginStreak: (_, action) => {
            return action.payload;
        },
        updateLoginStreak: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        }
    }
});

export const {
    setLoginStreak,
    updateLoginStreak,
} = loginStreakSlice.actions;

export default loginStreakSlice.reducer;