export default function useFetch() {
    async function _fetch(url, obj = {}) {
		try {
			const token = localStorage.getItem('grt-token');
			const baseURL = import.meta.env.VITE_APP_API_URL;
			const callURL = `${baseURL}/${url}`;
			let headers = {};
			let { method = 'GET', body = undefined } = obj;

			if (token){
				headers['Authorization'] = `Bearer ${token}`;
			}

			if (body) {
				headers['Content-Type'] = 'application/json';
			}

			headers['ngrok-skip-browser-warning'] = 'true';

			const options = {
				headers,
				body: JSON.stringify(body),
				method,
				credentials: 'include',
			};

			let res = await fetch(callURL, options);
			res = await res.json();
			return res;
		} catch (err) {
			console.error(err);
			return { success: false, message: 'Something went wrong' };
		}
	}
	return _fetch;
}