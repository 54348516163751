import { createSlice } from "@reduxjs/toolkit";

const initialNotificationState = {
    notifications: []
};

const notificationSlice = createSlice({
    name: "notifications",
    initialState: initialNotificationState,
    reducers: {
        setNotifications: (_, action) => {
            return action.payload;
        },
        updateNotifications: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        },
    }
});

export const {
    setNotifications,
    updateNotifications,
} = notificationSlice.actions;

export default notificationSlice.reducer;