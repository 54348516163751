import ReactDOM from 'react-dom/client'
import './index.css';
import DeviceWrapper from './DeviceWrapper.jsx';
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://566a9c9dcd474fc10c606c3d8b233c83@o4507476329037824.ingest.de.sentry.io/4507476331069520",
    integrations: [],
});

ReactDOM.createRoot(document.getElementById('root')).render(
    <DeviceWrapper />
);
