import { Layout } from 'antd';
import Header from "./Header";
import NavBar from './NavBar';

const { Content } = Layout;

export default function Main({
    children,
    showHeader = true,
    showStats = true
}){
    return (
        <main
            className="min-h-screen relative pb-32 pt-2"
        >
            {
                showHeader && (
                    <Header
                        showStats={showStats}
                    />
                )
            }
            <Content
                className="container"
            >
                {children}
            </Content>
            <div
                className="fixed bottom-2 w-full"
            >
                <NavBar />
            </div>
        </main>
    );
}