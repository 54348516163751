import WebApp from "@twa-dev/sdk";
import { ConfigProvider, theme, App as AntdApp } from "antd";
import { Provider } from "react-redux";
import App from "./App";

import store from './redux/store';

export default function ThemeWrapper(){
    return (
        <Provider store={store}>
            <ConfigProvider
                theme={{
                algorithm: theme.defaultAlgorithm,
                token: {
                    fontFamily: 'Work Sans, sans-serif',
                    colorPrimary: WebApp.themeParams.button_color,
                    colorText: WebApp.themeParams.text_color,
                    colorTextHeading: WebApp.themeParams.text_color,
                    colorLink: WebApp.themeParams.link_color,
                    colorError: WebApp.themeParams?.destructive_text_color || "#ff4d4f",
                }
                }}
            >
                <AntdApp>
                    <App />
                </AntdApp>
            </ConfigProvider>
        </Provider>
    );
}