import { createSlice } from "@reduxjs/toolkit";

const initialInvitedUserState = {
    invitedUsers: [],
};

const inivtedUserSlice = createSlice({
    name: "invitedUser",
    initialState: initialInvitedUserState,
    reducers: {
        setInvitedUsers: (_, action) => {
            return action.payload;
        },
        updateInvitedUsers: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        }
    }
});

export const {
    setInvitedUsers,
    updateInvitedUsers,
} = inivtedUserSlice.actions;

export default inivtedUserSlice.reducer;