import { createSlice } from "@reduxjs/toolkit";

const initialUserState = {
    telegram_id: "",
    first_name: "",
};

const userSlice = createSlice({
    name: "user",
    initialState: initialUserState,
    reducers: {
        login: (_, action) => {
            return action.payload;
        },
        logout: () => {
            return initialUserState;
        },
        update: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        }
    }
});

export const {
    login,
    logout,
    update,
} = userSlice.actions;

export default userSlice.reducer;