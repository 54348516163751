import { Button, Typography, message } from "antd";
import { PiUserCircleDuotone, PiCopySimpleDuotone, PiUserCircleCheckDuotone } from "react-icons/pi";
import WebApp from '@twa-dev/sdk'
import GiftBoxImage from "../assets/icons/gift-box.png";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { BsCoin } from "react-icons/bs";
import { useUser } from "../hooks/user";
import { useConsts } from "../hooks/consts";
import { useInvitedUsers } from "../hooks/invitedUser";
import { logFirebaseEvent } from "../services/firebase";

export default function Friends(){
    const navigate = useNavigate();
    const user = useUser();
    const consts = useConsts();
    const invitedUsers = useInvitedUsers();

    const baseInviteUrl = import.meta.env.VITE_TELEGRAM_BOT_LINK;

    const inviteUrl = `${baseInviteUrl}?startapp=${user.referral_code}`;
    const inviteText = `
🚀 **Join Hop Bot - Your Gateway to Crypto Coins!** 🌟

Hey there, Crypto Enthusiast! 🌈

Ready to hop into a new adventure with **Hop Bot**? 🤖💰

👉 Click, hop, and earn coins effortlessly with each tap!
👉 Your earned coins will be securely credited to your crypto wallet upon launch! 💼💸

Join the fun and start earning today! 🎉

Let's hop together towards a crypto-filled future! 🚀💎

#HopBot #CryptoCoins #CryptoAdventure`;

    useEffect(() => {
        function setBackAction(){
            WebApp.BackButton.show();
            WebApp.BackButton.onClick(() => {
                navigate("/");
            });
        }
        
        setBackAction();
    }, []);

    function openTelegramShare(){
        WebApp.HapticFeedback.impactOccurred("medium");
        WebApp.openTelegramLink(`https://t.me/share/url?url=${encodeURIComponent(inviteUrl)}&text=${encodeURIComponent(inviteText)}`);
        logFirebaseEvent("invite_friend", {
            inviteUrl
        });
    }

    function copyInviteLink(){
        try {
            WebApp.HapticFeedback.impactOccurred("medium");
            navigator.clipboard.writeText(inviteUrl);
            message.success("Invite link copied to clipboard.");
            logFirebaseEvent("copy_invite_link", {
                inviteUrl
            });
        } catch (err) {
            message.error("Failed to copy invite link to clipboard.");
        }
    }

    return(
        <div>
            <Typography.Title
                level={2}
                style={{
                    marginTop: "0"
                }}
                className="text-center"
            >
                Invite Friends
            </Typography.Title>
            <Typography.Text
                className="text-center"
            >
                You and your friend will recevie bonus coins.
            </Typography.Text>
            <div
                className="my-4 flex gap-x-2 items-center justify-between bg-[var(--tg-theme-accent-text-color)] rounded-2xl p-2"
            >
                <img
                    src={GiftBoxImage}
                    alt="Gift Box"
                    className="w-14"
                />
                <div
                    className="flex-1 text-[var(--tg-theme-button-text-color)]"
                >
                    <p
                        className="font-semibold text-left mt-0"
                    >
                        Invite a friend
                    </p>
                    <p
                        className="text-left m-0 inline-flex items-center gap-x-1 w-full"
                    >
                        <BsCoin
                            color="var(--tg-theme-button-text-color)"
                        />
                        <span
                            className="font-semibold"
                        >
                            {`+${consts.inviteRewards?.normal}`}
                        </span>
                        <span>
                            {`for you and your friend`}
                        </span>
                    </p>
                </div>
            </div>
            <div
                className="my-4 flex gap-x-2 items-center justify-between bg-[var(--tg-theme-accent-text-color)] rounded-2xl p-2"
            >
                <img
                    src={GiftBoxImage}
                    alt="Gift Box"
                    className="w-14"
                />
                <div
                    className="flex-1 text-[var(--tg-theme-button-text-color)]"
                >
                    <p
                        className="font-semibold text-left mt-0"
                    >
                        Invite a friend with Telegram Premium
                    </p>
                    <p
                        className="text-left m-0 inline-flex items-center gap-x-1 w-full"
                    >
                        <BsCoin
                            color="var(--tg-theme-button-text-color)"
                        />
                        <span
                            className="font-semibold"
                        >
                            {`+${consts.inviteRewards?.premium}`}
                        </span>
                        <span>
                            {`for you and your friend`}
                        </span>
                    </p>
                </div>
            </div>
            <div
                className="flex gap-x-2 items-center justify-between"
            >
                <Button
                    type="primary"
                    className="flex-1 rounded-lg shadow-none border-0 scale-up-down-animation"
                    style={{
                        height: "48px"
                    }}
                    icon={<PiUserCircleDuotone size={24} />}
                    iconPosition="left"
                    onClick={openTelegramShare}
                >
                    <span
                        className="font-semibold text-lg"
                    >
                        Invite a friend
                    </span>
                </Button>
                <Button
                    type="primary"
                    className="rounded-lg shadow-none border-0"
                    style={{
                        height: "48px",
                        width: "48px"
                    }}
                    icon={<PiCopySimpleDuotone size={28} />}
                    onClick={copyInviteLink}
                />
            </div>
            <Typography.Title
                level={4}
                className="text-center mt-6"
            >
                Invited Friends
            </Typography.Title>
            <div
            >
                {
                    invitedUsers.length === 0 && (
                        <Typography.Text
                            className="text-center"
                        >
                            No friends invited yet.
                        </Typography.Text>
                    )
                }
                {
                    invitedUsers.map((invitedUser) => (
                        <div
                            key={invitedUser._id}
                            className="my-2 flex gap-x-2 items-center justify-between bg-[var(--tg-theme-accent-text-color)] rounded-2xl p-2"
                        >
                            <div
                                className="flex gap-x-2 items-center"
                            >
                                <PiUserCircleCheckDuotone
                                    size={32}
                                    color="var(--tg-theme-button-text-color)"
                                />
                                <div
                                    className="flex flex-col"
                                >
                                    <Typography.Text
                                        className="text-left"
                                        style={{
                                            color: "var(--tg-theme-button-text-color)"
                                        }}
                                    >
                                        {invitedUser.first_name}
                                    </Typography.Text>
                                    <Typography.Text
                                        className="text-left"
                                        style={{
                                            color: "var(--tg-theme-button-text-color)"
                                        }}
                                    >
                                        {invitedUser.username}
                                    </Typography.Text>
                                </div>
                            </div>
                            
                        </div>
                    ))
                }
            </div>
        </div>
    );
}