import WebApp from "@twa-dev/sdk";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import GRTShadowPng from "../../assets/logo/grt-shadow.png";
import { Typography } from "antd";

export default function TON(){
    const navigate = useNavigate();
    useEffect(() => {
        function setBackAction(){
            WebApp.BackButton.show();
            WebApp.BackButton.onClick(() => {
                navigate("/");
            });
        }
        setBackAction();
    }, []);
    return (
        <div>
            <div
                className="flex justify-center items-center"
            >
                <img
                    src={GRTShadowPng}
                    alt="GRT"
                    className="h-72 object-contain"
                />
            </div>
            <Typography.Title
                level={2}
                className="text-center"
            >
                Airdrop
            </Typography.Title>
            <Typography.Text>
                Airdrop is on the way. Please stay tuned and till then keep mining.
            </Typography.Text>
        </div>
    )
}