import QRCodeImage from '../assets/images/hop_bot_qr.png';

export default function InvalidPlatform(){
    return (
        <div
            className="min-h-screen flex flex-col items-center justify-center bg-black"
        >
            <img
                src={QRCodeImage}
                alt="QR Code"
                className="w-1/2 rounded-lg max-w-96"
            />

            <h1
                className="text-white text-center text-xl mt-4"
            >
                Please scan the QR code using your mobile device to access this application.
            </h1>

        </div>
    )
}