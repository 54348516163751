import { createSlice } from "@reduxjs/toolkit";

export const maxClicks = 120;

const initialUserClickState = {
    clicks: 120,
    accumulatedClicks: 0,
};

const userClickSlice = createSlice({
    name: "userClick",
    initialState: initialUserClickState,
    reducers: {
        setUserClicks: (_, action) => {
            return action.payload;
        },
        updateUserClicks: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        },
        addUserClicks: (state, action) => {
            if (state.clicks >= maxClicks) {
                return state;
            }
            return {
                ...state,
                clicks: state.clicks + action.payload,
            };
        },
        addAccumulatedClicks: (state, action) => {
            return {
                ...state,
                accumulatedClicks: state.accumulatedClicks + action.payload,
            };
        },
        deductUserClicks: (state, action) => {
            if (state.clicks <= 0) {
                return state;
            }
            return {
                ...state,
                clicks: state.clicks - action.payload,
            };
        },
        deductAccumulatedClicks: (state, action) => {
            if (state.accumulatedClicks <= 0) {
                return state;
            }
            return {
                ...state,
                accumulatedClicks: state.accumulatedClicks - action.payload,
            };
        },
        clearAccumulatedClicks: (state) => {
            return {
                ...state,
                accumulatedClicks: 0,
            };
        },
    }
});

export const {
    setUserClicks,
    updateUserClicks,
    addUserClicks,
    addAccumulatedClicks,
    deductUserClicks,
    deductAccumulatedClicks,
    clearAccumulatedClicks,
} = userClickSlice.actions;

export default userClickSlice.reducer;