import { createSlice } from "@reduxjs/toolkit";

const initialTasksState = [];

const tasksSlice = createSlice({
    name: "tasks",
    initialState: initialTasksState,
    reducers: {
        setTasks: (_, action) => {
            return action.payload;
        },
        updateTasks: (state, action) => {
            return action.payload;
        },
        addTask: (state, action) => {
            return [...state, action.payload];
        },
        removeTask: (state, action) => {
            return state.filter((task) => task._id !== action.payload);
        },
        updateTask: (state, action) => {
            return state.map((task) => {
                if (task?._id?.toString() === action.payload?._id?.toString()) {
                    return {
                        ...task,
                        ...action.payload,
                    }
                }
                return task;
            });
        },
    }
});

export const {
    setTasks,
    updateTasks,
    addTask,
    removeTask,
    updateTask,
} = tasksSlice.actions;

export default tasksSlice.reducer;