import WebApp from "@twa-dev/sdk";
import { FaAngleRight } from "react-icons/fa6";
import { Typography } from "antd";
import { useEffect, useState } from "react"
import rocketImage from "../assets/icons/rocket-spaceship.png";
import GRTShadowPng from "../assets/logo/grt-shadow.png";
import { useUser } from "../hooks/user";
import { useConsts } from "../hooks/consts";
import {
    useDispatch
} from "react-redux";
import {
    update
} from "../redux/userSlice";
import useFetch from "../hooks/fetch";
import { useUserClicks } from "../hooks/userClicks";
import {
    deductUserClicks,
    maxClicks
} from "../redux/userClick";
import DolladImage from "../assets/icons/dollar.png";
import playCoinCreditAudio from "../services/playCoinCreditAudio";
import { logFirebaseEvent } from "../services/firebase";

export default function Home(){

    const _fetch = useFetch();
    const userClicks = useUserClicks();

    const dispatch = useDispatch();
    const user = useUser();
    const consts = useConsts();

    const currentCoins = user.coins;
    const coinsToUpgrade = consts.levelThresholds[user.level];

    const percentage = (currentCoins / coinsToUpgrade) * 100;

    const [isPressed, setIsPressed] = useState(false);

    const handlePress = () => {
        setIsPressed(true);
    };

    const handleRelease = () => {
        setIsPressed(false);
    };


    useEffect(() => {
        function setBackAction(){
            WebApp.BackButton.hide();
        }
        
        setBackAction();
    }, []);

    function createRipple(event) {
    try {
        const ripple = document.createElement('p');
        ripple.textContent = `+${user.earn_per_hop}`;
        ripple.classList.add('ripple');
        ripple.classList.add('text-2xl');
        const rect = event.currentTarget.getBoundingClientRect();
        const offsetX = event.clientX - rect.left;
        const offsetY = event.clientY - rect.top;
        ripple.style.left = `${offsetX}px`;
        ripple.style.top = `${offsetY}px`;
        event.currentTarget.appendChild(ripple);
        setTimeout(() => {
            ripple.remove();
        }, 2000);
        } catch (err) {
            console.error(err);
        }
    }

    async function addClickCoins(){
        try {
            const res = await _fetch("user/click-to-earn", {
                method: "POST",
                body: {}
            });
            if (res.success){
                dispatch(update(res.response))
                logFirebaseEvent("click_to_earn_success");
            }
        } catch (err) {
            console.error(err);
        }
    }


    async function processClicks(event){
        if (userClicks.clicks > 0){
            createRipple(event);
            WebApp.HapticFeedback.impactOccurred("light");
            // playCoinCreditAudio();
            dispatch(deductUserClicks(1));
            addClickCoins();
        }
    }


    function onCoinClick(event){
        processClicks(event);
        logFirebaseEvent("click_to_earn");
    }


    return (
        <div>
            <Typography.Title
                className="text-center"
                style={{
                    fontWeight: 700
                }}
            >
                <span
                    className="inline-flex items-center gap-x-1"
                >
                    <img
                        src={DolladImage}
                        alt="Dollar"
                        className="h-8"
                    />
                    <span>
                        {user.coins}
                    </span>
                </span>
            </Typography.Title>
            <div>
                <div
                    className="w-full flex justify-between items-center"
                >
                    <div
                        className="flex items-center gap-x-1"
                    >
                        <span
                            className="font-semibold text-sm"
                        >
                            {consts.levelNameMapping[user.level]}
                        </span>
                        <FaAngleRight
                            className="text-sm"
                            color="var(--tg-theme-text-color)"
                        />
                    </div>
                    <span
                        className="text-sm"
                    >
                        <span>
                            Level
                        </span>
                        <span
                            className="font-semibold"
                        >
                            {` ${user.level}`}
                        </span>
                    </span>
                </div>
                <div
                    className="rounded-full h-3 w-full relative overflow-hidden mt-1"
                    style={{
                        backgroundColor: "var(--tg-theme-text-color)"
                    }}
                >
                    <div
                        className="absolute top-0 left-0 h-full rounded-full"
                        style={{
                            background: "linear-gradient(to right, var(--tg-theme-button-text-color) 10%, #fdd6bd 40%, var(--tg-theme-button-color) 100%)",
                            width: `${percentage}%`
                        }}
                    />
                </div>
                <div
                    className="flex flex-col items-center gap-y-2 mt-16"
                >
                    <div
                        className="rounded-full overflow-hidden custom-ripple-container"
                        onClick={onCoinClick}
                        onTouchStart={handlePress}
                        onTouchEnd={handleRelease}
                        onTouchCancel={handleRelease}
                        onMouseDown={handlePress}
                        onMouseUp={handleRelease}
                        onMouseLeave={handleRelease}
                    >
                        <img
                            src={GRTShadowPng}
                            alt="GRT"
                            className={`h-72 object-contain rounded-full transition-all ${isPressed ? "scale-95" : "scale-100"}`}
                        />
                    </div>
                    <div
                        className="w-full"
                    >
                        <div
                            className="flex items-center gap-x-2"
                        >
                            <img
                                src={rocketImage}
                                alt="rocket"
                                className="h-8"
                            />
                            <span>
                                <span
                                    className="font-semibold"
                                >
                                    {userClicks?.clicks || 0}
                                </span>
                                <span>
                                    / {maxClicks}
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}