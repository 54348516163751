import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import constReducer from "./constSlice";
import inivtedUserReducer from "./invitedUserSlice";
import loginStreakReducer from "./loginStreakSlice";
import tasksReducer from "./tasksSlice";
import userClickReducer from "./userClick";
import notificationReducer from "./notificationSlice";

const rootReducer = combineReducers({
    user: userReducer,
    consts: constReducer,
    invitedUser: inivtedUserReducer,
    loginStreak: loginStreakReducer,
    tasks: tasksReducer,
    userClick: userClickReducer,
    notifications: notificationReducer,
});

export default rootReducer;