import { Button, Typography } from "antd";
import BottomSheet from "./BottomSheet";
import DollarIcon from "../assets/icons/dollar.png";
import { formatNumber } from "../services/formatNumber";

export default function CoinsCreditedBottomSheet({
    coins,
    onClose,
    isOpen
}){
    return(
        <BottomSheet
            isOpen={isOpen}
            onClose={onClose}
        >
            <div
                className="w-full flex flex-col items-center gap-y-4"
            >
                <img
                    src={DollarIcon}
                    alt="Dollar"
                    className="w-40 object-contain"
                />

                <Typography.Title
                    className="text-center"
                    style={{
                        marginBottom: 0
                    }}
                >
                    {`+${formatNumber(coins)} GRTs`}
                </Typography.Title>
                <Typography.Text
                    className="text-center"
                >
                    <span>
                        Credited to your account. Keep hopping,
                    </span>
                    <br />
                    <span>
                        Keep earning more coins.
                    </span>
                </Typography.Text>

                <Button
                    type="primary"
                    className="w-full"
                    onClick={onClose}
                    style={{
                        height: "48px"
                    }}
                >
                    <span
                        className="text-lg font-semibold"
                    >
                        Done
                    </span>
                </Button>

            </div>
        </BottomSheet>
    )
}