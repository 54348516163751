import { Typography } from "antd";

export default function ErrorScreen(){
    return (
        <div
            className="h-screen w-screen flex items-center justify-center"
        >
            <Typography.Title>
                Something went wrong
            </Typography.Title>
        </div>
    )
}