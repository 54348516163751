import { Button } from "antd";
import { useConsts } from "../hooks/consts"
import { formatNumber } from "../services/formatNumber";
import coinSVG from "../assets/svgs/coin.svg";
import { useUserStreak } from "../hooks/userStreak";

export default function DailyTask({
    onTaskComplete = () => {},
    loading = false
}){
    const {
        streakRewards
    } = useConsts();

    const currentStreak = useUserStreak();

    const currentStreakNumber = currentStreak?.current_streak || 0;
    const lastKeyInStreakRewards = Object.keys(streakRewards).reduce((a, b) => parseInt(a) > parseInt(b) ? a : b);


    return (
        <div
            className="w-full flex flex-col gap-y-4"
        >
            <h2
                className="text-center mb-0"
            >
                Claim your daily reward
            </h2>
            <div
                className="grid grid-cols-4 gap-2"
            >
                {
                    Object.keys(streakRewards).map((key) => {
                        const completed = parseInt(key) <= currentStreakNumber;
                        return (
                            <div
                                key={`streak-reward-${key}`}
                                className={`flex flex-col items-center gap-1 justify-center rounded-xl p-2 border-2 border-[var(--tg-theme-accent-text-color)] border-solid ${completed ? "bg-[var(--tg-theme-accent-text-color)]" : ""}`}
                            >
                                <div
                                    className="relative flex flex-col items-center justify-center h-12 w-12 bg-[#f3c70d] rounded-full"
                                >
                                    <img
                                        src={coinSVG}
                                        alt="coin"
                                        className="h-full w-full z-[1] absolute top-0 left-0 right-0 bottom-0"
                                    />
                                    <span
                                        className="text-lg font-semibold z-[2] text-white"
                                    >
                                        {key}
                                    </span>
                                </div>
                                <span
                                    className="text-lg font-semibold"
                                >
                                    {formatNumber(streakRewards[key])}
                                </span>
                            </div>
                        )
                    })
                }
            </div>
            <Button
                type="primary"
                className="w-full"
                onClick={onTaskComplete}
                style={{
                    height: "48px"
                }}
                loading={loading}
                disabled={currentStreakNumber >= lastKeyInStreakRewards}
            >
                <span
                    className="text-lg font-semibold"
                >
                    Claim Daily Reward
                </span>
            </Button>
        </div>
    )
}