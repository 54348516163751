import { useEffect, useState } from 'react';
import NavigationWrapper from './NavigationWrapper.jsx';
import LoadingScreen from './pages/LoadingScreen.jsx';
import ErrorScreen from './pages/ErrorScreen.jsx';
import useFetch from './hooks/fetch.js';
import { message } from 'antd';
import WebApp from '@twa-dev/sdk';
import { useDispatch } from "react-redux";
import { setConsts } from './redux/constSlice.js';
import {
  login
} from './redux/userSlice.js';
import { setInvitedUsers } from './redux/invitedUserSlice.js';
import { setLoginStreak } from './redux/loginStreakSlice.js';
import { setTasks } from './redux/tasksSlice.js';
import { setFirebaseAnalyticsUser } from './services/firebase.js';
import { setNotifications } from './redux/notificationSlice.js';

function App() {
  const _fetch = useFetch();
  const dispatch = useDispatch();
  const [state, setState] = useState("loading"); // loading, success, error

  const loading = state === "loading";
  const error = state === "error";

  function saveTokenToLocalStorage(token){
    localStorage.setItem("grt-token", token); 
  }

  async function getConsts(){
    const consts = await _fetch("consts");
    if (consts.success){
      dispatch(setConsts(consts.response));
    } else {
      throw new Error("Failed to get consts");
    }
  }

  async function getInvitedUsers(){
    const res = await _fetch("user/invited");
    if (res.success){
      dispatch(setInvitedUsers(res.response));
    } else {
      throw new Error("Failed to get invited users");
    }
  }

  async function getLoginStreak(){
    const res = await _fetch("user/login-streak");
    if (res.success){
      dispatch(setLoginStreak(res.response));
    } else if (res.status === 404){
      // User has not logged in yet
    } else {
      throw new Error("Failed to get login streak");
    }
  }

  async function getTasks(){
    const res = await _fetch("task");
    if (res.success){
      dispatch(setTasks(res.response));
    } else {
      throw new Error("Failed to get tasks");
    }
  }

  async function getNotifications(){
    const res = await _fetch("notification");
    if (res.success){
      dispatch(setNotifications(res.response));
    } else {
      throw new Error("Failed to get notifications");
    }
  }

  function setFirebaseUserForAnalytics(user){
    setFirebaseAnalyticsUser(user?._id, {
      name: user?.first_name,
      telegram_id: user?.telegram_id,
    });
  }


  async function init(){
    try {
      let isError = false;
      // Try Cookie Login
      const loggedInUser = await _fetch("user");
      if (loggedInUser.success){
        dispatch(login(loggedInUser.response));
        setFirebaseUserForAnalytics(loggedInUser.response);
      } else {
        // Try Telegram Login
        const loginRes = await _fetch("user/login", {
          method: "POST",
          body: {
            "telegram_id": WebApp?.initDataUnsafe?.user?.id?.toString(),
          }
        });
        if (loginRes.success){
          saveTokenToLocalStorage(loginRes.response?.token);
          dispatch(login(loginRes.response?.user));
          setFirebaseUserForAnalytics(loginRes.response?.user);
        } else if (loginRes.status === 404){
          // Sign Up
          const userBody = {
            first_name: WebApp?.initDataUnsafe?.user?.first_name,
            last_name: WebApp?.initDataUnsafe?.user?.last_name,
            username: WebApp?.initDataUnsafe?.user?.username,
            telegram_id: WebApp?.initDataUnsafe?.user?.id?.toString(),
            is_telegram_premium: WebApp?.initDataUnsafe?.user?.is_premium || false,
            language_code: WebApp?.initDataUnsafe?.user?.language_code || "en",
            referral_code: WebApp?.initDataUnsafe?.start_param || ""
          }

          const registerRes = await _fetch("user/register", {
            method: "POST",
            body: userBody
          });

          if (registerRes.success){
            saveTokenToLocalStorage(registerRes.response?.token);
            dispatch(login(registerRes.response?.user));
            setFirebaseUserForAnalytics(registerRes.response?.user);
          } else {
            isError = true;
          }
        } else {
          isError = true;
        }
      }
      await getConsts();
      await getInvitedUsers();
      await getLoginStreak();
      await getTasks();
      await getNotifications();
      if (isError){
        setState("error");
        message.error("Something went wrong, please re open the app");
      } else {
        setState("success");
      }
    } catch (err) {
      console.error(err);
      message.error("Something went wrong, please re open the app");
      setState("error");
    }
  }

  useEffect(() => {
    init();
  }, []);

  if (loading){
    return (
      <LoadingScreen />
    );
  }

  if (error){
    return (
      <ErrorScreen />
    )
  }

  return (
    <NavigationWrapper />
  );
}

export default App
