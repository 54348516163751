import { useEffect, useState } from "react";
import moment from "moment";
import useFetch from "../hooks/fetch";
import { useDispatch } from "react-redux";
import { setNotifications } from "../redux/notificationSlice";
import { IoClose } from "react-icons/io5";
import { message, Spin, Typography } from "antd";
import { useNotifications } from "../hooks/notification";
import DollarImage from "../assets/icons/dollar.png";
import { formatNumber } from "../services/formatNumber";

export default function Notifications(){
    const _fetch = useFetch();
    const dispatch = useDispatch();
    const notifications = useNotifications();
    const [state, setState] = useState("loading"); // ["loading", "success", "error"]
    const [selectedNotification, setSelectedNotification] = useState(null);

    async function onNotificationRead(notificationId){
        if (selectedNotification !== null) return;
        try {
            setSelectedNotification(notificationId);
            const res = await _fetch(`notification/${notificationId}`);
            if (res.success){
                dispatch(setNotifications(notifications?.filter((notification) => notification?._id?.toString() !== notificationId?.toString())));
            } else {
                message.error("Failed to mark notification as read");
            }
        } catch (err) {
            console.error(err);
            message.error("Failed to mark notification as read");
        } finally {
            setSelectedNotification(null);
        }
    }

    useEffect(() => {
        async function init(){
            try {
                const res = await _fetch("notification");
                if (res.success){
                    dispatch(setNotifications(res.response));
                    setState("success");
                } else {
                    setState("error");
                }
            } catch (err) {
                console.error(err);
                setState("error");
            }
        }

        init();
    }, []);

    if (state === "loading"){
        return (
            <div
                className="min-h-screen flex flex-col items-center justify-center"
            >
                <Spin
                    size="large"
                />
            </div>
        )
    }

    if (state === "error"){
        return (
            <div
                className="min-h-screen flex flex-col items-center justify-center"
            >
                <h2
                    className="text-center"
                >
                    Error loading notifications
                </h2>
            </div>
        )
    }

    return (
        <div
            className="min-h-screen flex flex-col items-center gap-y-4"
        >
            <Typography.Title
                level={2}
                style={{
                    marginTop: "0"
                }}
                className="text-center"
            >
                Notifications
            </Typography.Title>
            {
                notifications?.length === 0 && (
                    <Typography.Text
                        className="text-center"
                    >
                        No notifications
                    </Typography.Text>
                )
            }
            <div
                className="flex flex-col gap-y-2 w-full"
            >
                {
                    notifications?.map((notification) => (
                        <div
                            key={notification?._id}
                            className="flex items-center justify-between bg-[var(--tg-theme-accent-text-color)] rounded-2xl p-2 relative"
                        >
                            {
                                parseInt(notification?.coins || 0) > 0 && (
                                    <div
                                        className="mr-2 flex flex-col items-center gap-y-1"
                                    >
                                        <img
                                            src={DollarImage}
                                            alt="Dollar"
                                            className="w-8 h-8"
                                        />
                                        <p
                                            className="m-0 text-xs"
                                        >
                                            {formatNumber(notification?.coins)}
                                        </p>
                                    </div>
                                )
                            }
                            <div
                                className="flex-1 text-[var(--tg-theme-button-text-color)]"
                            >
                                <div
                                    className="flex gap-x-2 justify-between"
                                >
                                    <h5
                                        className="text-left font-medium m-0 text-base flex-1"
                                    >
                                        {notification?.title}
                                    </h5>
                                    <p
                                        className="text-right m-0 text-xs"
                                    >
                                        {
                                            moment(notification?.date).fromNow()
                                        }
                                    </p>
                                </div>
                                <p
                                    className="text-left m-0 text-sm"
                                >
                                    {notification?.message}
                                </p>
                            </div>
                            <div
                                onClick={() => onNotificationRead(notification?._id)}
                                className="absolute h-4 w-4 top-0 right-0 transform -translate-y-1/3 bg-[var(--tg-theme-button-text-color)] p-1 rounded-full flex items-center justify-center cursor-pointer shadow-md"
                            >
                                {
                                    selectedNotification === notification?._id ? (
                                        <Spin
                                            size="small"
                                        />
                                    ) : (
                                        <IoClose
                                            color="var(--tg-theme-accent-text-color)"
                                            size={16}
                                        />
                                    )
                                }
                            </div>
                        </div>
                    ))   
                }
            </div>
        </div>
    )
}