import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setUserId, setUserProperties } from "firebase/analytics";

const firebaseConfig = {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
    measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID
};

let fireabseApp = null;
let firebaseAnalytics = null;


if (firebaseConfig.apiKey){
    fireabseApp = initializeApp(firebaseConfig);
    firebaseAnalytics = getAnalytics(fireabseApp);
    console.log("Firebase initialized");
} else {
    console.log("Firebase not initialized");
}

export function logFirebaseEvent(eventName, eventParams){
    if (firebaseAnalytics){
        logEvent(firebaseAnalytics, eventName, eventParams);
    }
}

export function setFirebaseAnalyticsUser(userId, properties = {}){
    if (firebaseAnalytics){
        setUserId(firebaseAnalytics, userId?.toString());
        setUserProperties(firebaseAnalytics, properties);
    }
}
